import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("Shipping address")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, {
              slot: "end",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.$t('First name'),
                  class: "ion-text-right",
                  name: "firstName",
                  modelValue: _ctx.address.firstName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address.firstName) = $event)),
                  id: "firstName",
                  type: "text"
                }, null, 8, ["label", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.$t('Last name'),
                  class: "ion-text-right",
                  name: "lastName",
                  modelValue: _ctx.address.lastName,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address.lastName) = $event)),
                  id: "lastName",
                  type: "text"
                }, null, 8, ["label", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.$t('Street'),
                  class: "ion-text-right",
                  name: "street",
                  modelValue: _ctx.address.address1,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.address.address1) = $event)),
                  id: "address1",
                  type: "text"
                }, null, 8, ["label", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.$t('City'),
                  class: "ion-text-right",
                  name: "city",
                  modelValue: _ctx.address.city,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.address.city) = $event)),
                  id: "city",
                  type: "text"
                }, null, 8, ["label", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select, {
                  label: _ctx.$t('State'),
                  interface: "popover",
                  modelValue: _ctx.address.stateProvinceGeoId,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.address.stateProvinceGeoId) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states, (state) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: state.geoId,
                        value: state.geoId
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(state.geoName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_input, {
                  label: _ctx.$t('Zipcode'),
                  class: "ion-text-right",
                  name: "zipcode",
                  modelValue: _ctx.address.postalCode,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.address.postalCode) = $event)),
                  id: "postalCode"
                }, null, 8, ["label", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_button, {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateAddress()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("Save shipping address")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ], 64))
}